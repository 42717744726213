import * as React from 'react';

import {
  HeadMeta,
  HomeHero,
  IClickToActionProps,
  IInfoLink,
  IMenuLink,
  ITileProps,
  Layout,
  MainHero,
  SmallHero,
} from '../../components';
import { RichTextRender } from '../../components/RichTextRender';
import { LanguageContext } from '../../context/LanguageContext';
import { RichTextElements } from '../../utils/fragments/types';
import {
  IContentfulVacationBanner,
  ICustomGatsbyImageData,
  ILocation,
  IMetaTagField,
} from '../../utils/types';

interface ISubCategoryComponentProps {
  menuLinks: IMenuLink[];
  infoLinks: IInfoLink[];
  staticLocation?: ILocation;
  title: string;
  subHeading?: string;
  headerType: string;
  featuredImage?: ICustomGatsbyImageData;
  description?: {
    description: string;
  };
  sectionId?: string;
  pageContent: {
    raw: string;
    references: RichTextElements[];
  };
  tiles?: ITileProps[];
  clickToAction?: IClickToActionProps;
  clickToActionPrimaryButton?: IClickToActionProps;
  meta?: IMetaTagField[];
  vacationBanner?: IContentfulVacationBanner;
  language: 'De' | 'En';
}

export const SubCategoryComponent: React.FunctionComponent<ISubCategoryComponentProps> = (
  props
) => {
  return (
    <LanguageContext.Provider value={props.language}>
      <Layout
        links={props.menuLinks}
        infoLinks={props.infoLinks}
        vacationBanner={props.vacationBanner}
      >
        <HeadMeta title={props.title} meta={props.meta} lang={props.language.toLowerCase()} />
        {
          {
            'Home Hero': (
              <HomeHero
                title={props.title}
                image={props.featuredImage}
                subTitle={props.subHeading}
              />
            ),
            'Small Hero': (
              <SmallHero
                title={props.title}
                featuredImage={props.featuredImage}
                subheading={props.subHeading}
                description={props.description?.description}
                location={props.staticLocation}
              />
            ),
            'Main Hero': (
              <MainHero
                title={props.title}
                image={props.featuredImage}
                description={props.description}
                sectionId={props.sectionId}
                location={props.staticLocation}
                tiles={props.tiles || null}
                clickToActionTile={props.clickToAction}
                clickToActionPrimaryButton={props.clickToActionPrimaryButton}
              />
            ),
          }[props.headerType]
        }
        {props.pageContent && <RichTextRender content={props.pageContent} />}
      </Layout>
    </LanguageContext.Provider>
  );
};
